function menu () {
    switch(window.location.pathname){
        case '/unternehmen' :
        case '/geschaeftsfuehrung':
        case '/zertifizierungen':
        case '/stellenangebote':
        case '/schulungsangebote':
            document.getElementById('link_navigation_bar_19').firstChild.firstChild.classList.remove("text-dark");
            document.getElementById('link_navigation_bar_19').firstChild.firstChild.classList.add("text-danger");
            break;
        case '/loesungen':
        case '/geruestbau':
            document.getElementById('link_navigation_bar_20').firstChild.firstChild.classList.remove("text-dark");
            document.getElementById('link_navigation_bar_20').firstChild.firstChild.classList.add("text-danger");
            break;
        case '/referenzen':
            document.getElementById('link_navigation_bar_21').firstChild.classList.remove("text-dark");
            document.getElementById('link_navigation_bar_21').firstChild.classList.add("text-danger");
            break;
        case '/partner':
            document.getElementById('link_navigation_bar_22').firstChild.classList.remove("text-dark");
            document.getElementById('link_navigation_bar_22').firstChild.classList.add("text-danger");
            break;
        case '/news':
            document.getElementById('link_navigation_bar_1010').firstChild.classList.remove("text-dark");
            document.getElementById('link_navigation_bar_1010').firstChild.classList.add("text-danger");
            break;
        case '/kontakt':
            document.getElementById('link_navigation_bar_164').firstChild.classList.remove("text-dark");
            document.getElementById('link_navigation_bar_164').firstChild.classList.add("text-danger");
            break;
        default:
            if(window.location.pathname.includes('schulungsangebote')) {
                document.getElementById('link_navigation_bar_19').firstChild.firstChild.classList.remove("text-dark");
                document.getElementById('link_navigation_bar_19').firstChild.firstChild.classList.add("text-danger");
            }
            if(window.location.pathname.includes('news')) {
                document.getElementById('link_navigation_bar_1010').firstChild.classList.remove("text-dark");
                document.getElementById('link_navigation_bar_1010').firstChild.classList.add("text-danger");

            }
    }

}

export {menu}