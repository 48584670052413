import $ from 'jquery'
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover'
import 'bootstrap/js/dist/tab'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '../scss/application.scss'
import { menu } from './menubar';

import Turbolinks from 'turbolinks'
Turbolinks.start();

jQueryBridget('imagesLoaded', imagesLoaded, $ );
jQueryBridget( 'masonry', Masonry, $ );


document.addEventListener("turbolinks:load", function () {

  menu();
  if(window.location.pathname === '/index' || window.location.pathname === '/' || window.location.pathname==='/admin/pages/2/edit') {
   let nav_links_a= document.getElementsByClassName("nav-link");
   for(let i = 0; i < nav_links_a.length; i++) {
     nav_links_a[i].classList.remove("text-dark");
   }
   $('.kg-link').css("color","white");
   $('#navbar-toggler').addClass("index-toggle");
   $('#navbar-toggler-icon').addClass("index-toggle-icon");

  }


  function openCollapse(element) {
    let id = "element_" + element.id;
    document.getElementById(id).style.display = "inline-block";
    document.getElementById(element.id).style.display = "none";
    document.getElementById('hide_'+element.id).style.display = "inline-block";
    $('.grid').masonry({
      itemSelector: '.grid-item'
    });
  }

  function closeCollapse(element) {
    let string = element.id.slice(5);
    document.getElementById(element.id).style.display = "none";
    document.getElementById(string).style.display = "inline-block";
    document.getElementById('element_' + string).style.display = "none";
    $('.grid').masonry({
      itemSelector: '.grid-item'
    });
  }
  window.openCollapse = openCollapse;
  window.closeCollapse = closeCollapse;

  $('#stellenangebote').append(
      "<div id='ebms-jobwidget'><div data-jobwidget='https://bms.empfehlungsbund.de/job_widgets/7ab4e7eac74839c9698d0a01d9b57584da236dffbfce92c7add400dc3beb699d'><noscript id='ebms-fallback'><p>Bitte aktivieren Sie JavaScript, um alle Funktionen des Bewerbungssystems zu nutzen.</p><iframe border='0' scrolling='yes' src='https://bms.empfehlungsbund.de/job_widgets/7ab4e7eac74839c9698d0a01d9b57584da236dffbfce92c7add400dc3beb699d' style='min-height: 600px; width: 100%'></iframe></noscript><script onerror='document.getElementById('ebms-jobwidget').innerHTML+=document.getElementById('ebms-fallback').innerHTML' src='https://bms.empfehlungsbund.de//widget.js'></script></div></div>")
/*
   News - Beim Klicken auf den Button werden die nächsten 6 Elemente angezeigt
   */

/*
  let news_node =  document.getElementById("news");
  if (document.body.contains(news_node)) {
    for (let i = 0; i < news_node.children.length; i++) {
      let yo = document.getElementById("news").children[i];
      if (i < 6) {
        yo.style.display = "inline-block"
      }
    }
  }

  $('#button').click(function () {
    let count = 6;
    for (let i = 0; i < document.getElementById("news").children.length; i++) {
      let yo = document.getElementById("news").children[i];
      if ($(yo).is(":hidden") && count !== 0) {
        count--;
        yo.style.display = "inline-block";
        console.log("hello");
      }
    }
    // Wenn alle Elemente angezeigt werden, dann verstecke den button

    if ($(document.getElementById("news").lastChild).is(":visible")) {
      document.getElementById("button").style.display = "none"
    }
    $('.grid').masonry({
      itemSelector: '.grid-item'
    });
  });

*/



  if(document.body.contains(document.getElementById("banner"))) {
    if (window.innerWidth < 576) {
      document.getElementById("banner").firstChild.firstChild.style.height = '600px';
    }
    else if (window.innerWidth < 991) {
      document.getElementById("banner").firstChild.firstChild.style.height = '700px';
    }
    else {
      document.getElementById("banner").firstChild.firstChild.style.height = '1000px';
    }
    var element = document.getElementById("navbarNav").firstChild;

    for (let i = 0; i < element.childElementCount; i++) {
      element.children[i].firstChild.style.color = "white";
    }
  }



  if (document.body.contains(document.getElementById("history_timeline"))){
    var element = document.getElementById("history_timeline");
    for (let i = 0; i < element.children.length; i++) {
      if (i % 2 === 0) {
        element.children[i].classList.add('left');
      } else {
        element.children[i].classList.add('right');
      }
    }
  }


    var $grid = $('.grid').masonry({
      itemSelector: '.grid-item'
    })

  $grid.imagesLoaded(function () {
      if (document.body.contains(document.getElementById('news-loader'))){
        $('#news-loader').remove()
      }
      $grid.removeClass('d-none')
      $grid.masonry();
  })


  $(document).on('show.bs.modal', (event) => {
    const modal = event.target;
    modal.querySelectorAll('.js-projects').forEach((div) => {
      const id = div.dataset.id;
      $.get(`/js/projects/${id}.json`, function(response) {
        div.innerHTML = response.template
      })
    })
  })
  //navbar

  $('#navbarNav').on('show.bs.collapse', (event) => {
    if (document.body.contains(document.getElementById('video'))) {
        $('#video').addClass("invisible")
    }
      });

  $('#navbarNav').on('hidden.bs.collapse', (event) => {
    if (document.body.contains(document.getElementById('video'))) {
      $('#video').removeClass("invisible")
    }
  });



});
